exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mvp-tsx": () => import("./../../../src/pages/mvp.tsx" /* webpackChunkName: "component---src-pages-mvp-tsx" */),
  "component---src-pages-planning-tsx": () => import("./../../../src/pages/planning.tsx" /* webpackChunkName: "component---src-pages-planning-tsx" */),
  "component---src-pages-product-big-question-funnel-tsx": () => import("./../../../src/pages/product/big-question-funnel.tsx" /* webpackChunkName: "component---src-pages-product-big-question-funnel-tsx" */),
  "component---src-pages-product-narrowing-the-scope-tsx": () => import("./../../../src/pages/product/narrowing-the-scope.tsx" /* webpackChunkName: "component---src-pages-product-narrowing-the-scope-tsx" */),
  "component---src-pages-product-user-stories-tsx": () => import("./../../../src/pages/product/user-stories.tsx" /* webpackChunkName: "component---src-pages-product-user-stories-tsx" */),
  "component---src-pages-product-user-workflows-tsx": () => import("./../../../src/pages/product/user-workflows.tsx" /* webpackChunkName: "component---src-pages-product-user-workflows-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */)
}

